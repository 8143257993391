/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useContext } from "react";

import { AuthContext, ProjectContext } from "../../context";

import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { doc, getFirestore, updateDoc } from "firebase/firestore";

function Overview() {
  const { AppcurrentUser } = useContext(AuthContext);
  const { Settings } = useContext(ProjectContext);
  const [isDirty, setisDirty] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);

  const [formState, setFormState] = useState({
    QRPrefix: "",
    QRSuffix: "",
    DefaultOutputType: "",
    PrintQR: false,
    OptType: "0",
    OutputType: "0",
    DropLabels: "0",
    StockLength: 0,
    OverMeasure: 0,
    UsefulScrap: 0,
    Iterations: 0,
    Generations: 0,
    Crossover: 0,
    Mutation: 0,
    Randomness: 0,
    SawKerf: 0,
    FractionView: false,
    SingleMiter: 0,
    DoubleMiter: 0,
  });
  const handleChange = (field) => (newValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: newValue, // Update the specific field with newValue
    }));
  };

  useEffect(() => {
    Object.keys(Settings).forEach((key) => handleChange(key)(Settings[key]));
  }, [Settings]);

  const handleSave = (event) => {
    event.preventDefault();

    const SaveData = async () => {
      if (!AppcurrentUser) {
        console.error("No user is signed in.");
        return;
      }
      const userId = AppcurrentUser.uid;
      const projectRef = doc(getFirestore(), "customers", userId);
      try {
        await updateDoc(projectRef, formState);
        setisDirty(false);
        openModal();
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    };

    SaveData();
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const DynamicAlert = (color, icon, title, Open, Close, content = "") => {
    return (
      <MDSnackbar
        color={color}
        icon={icon}
        title={title}
        content={content}
        dateTime="now"
        open={Open}
        onClose={Close}
        close={Close}
        bgWhite
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <MDButton
            variant="gradient"
            className={`${isDirty ? "isDirty" : ""}`}
            color={"info"}
            onClick={handleSave}
          >
            <Icon sx={{ fontWeight: "bold" }}>save</Icon>
            Save
          </MDButton>
          <PlatformSettings settings={formState} handleChange={handleChange} />
        </MDBox>
        {ModalOpen && DynamicAlert("success", "star", "Saved!", ModalOpen, closeModal)}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
