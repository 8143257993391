import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FeatureList() {
  return (
    <MDBox pt={8} pb={6}>
      <MDTypography variant="h3" fontWeight="bold" mb={2}>
        Key Features
      </MDTypography>
      <MDTypography variant="h6" mt={2}>
      {"Cutlist Generation: Generate precise and optimized cutlists in mere seconds, saving valuable time and reducing material waste. Our system ensures accuracy in every calculation, enabling seamless planning for your projects. Whether you're working on a small-scale or industrial-level operation, our cutlist generation tool provides unparalleled efficiency."}</MDTypography>
      <MDTypography variant="h6" mt={2}>
      Special Part Markings: Enhance part identification and workflow efficiency with advanced marking options. Easily mark miters for precise assembly and add QR codes to streamline tracking and inventory management. These markings simplify complex projects by enabling quick part identification and hassle-free progress monitoring on the shop floor or at the construction site.
      </MDTypography>
      <MDTypography variant="h6" mt={2}>
      Import and Export Functionality: Simplify data management with versatile import and export capabilities. Import part details using various file formats to integrate seamlessly with your existing tools. Export your project data to Excel for comprehensive reporting or shareable documentation. Future-proof your operations with upcoming support for direct CNC saw exports, enabling end-to-end automation.
      </MDTypography>
      <MDTypography variant="h6" mt={2}>
      Multiple Optimization Algorithms: Optimize material usage with a choice of cutting-edge algorithms tailored to your priorities. Use the First Fit Decreasing algorithm for lightning-fast results or opt for the Genetic Mutation Algorithm to achieve higher yield and reduce waste. These algorithms adapt to your unique requirements, balancing speed and efficiency for optimal performance.
   </MDTypography>
      </MDBox>
  );
}

export default FeatureList;
