/*eslint-disable no-unused-vars*/
import React, { useState, useEffect, useContext, useRef } from "react";
import { ProjectContext, AuthContext } from "../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import ImageModal from "components/ImageModal";
import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

const Tables = () => {
  const apiRef = useGridApiRef();
  const { Materials, addMaterial } = useContext(ProjectContext);
  const { AppcurrentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const Editrow = ({}) => (
    <Card>
      <MDBox
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={5} md={3} lg={2}>
            <MDButton
              color="info"
              startIcon={<Icon>print</Icon>}
              onClick={(e) => {
                addMaterial({
                  Weightft: "",
                  Costft: "",
                  SKU: "",
                  Status: "2",
                  Notes: "",
                  materialName: "New",
                  materialDesc: "New",
                  Image: "",
                  ImagePath: "",
                });
              }}
            >
              New Material
            </MDButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <GridToolbarExport sx={{}} csvOptions={{ fileName: `Material List` }} />
          </Grid>

          <Grid item xs={8} md={5} lg={3}>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );

  const Project = ({ image, name, openModal }) => {
    const ImageRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
      const wrapper = ImageRef.current;
      const rect =
        wrapper.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();
      const scaleFactor = Math.max(rect.width, rect.height) / 100;
      if (!isClicked) {
        wrapper.style.zIndex = "99";
        wrapper.style.transform = `scale(1)`;
      } else if (wrapper.textContent != "p" && isClicked) {
        wrapper.style.zIndex = "9999999";
        wrapper.style.backgroundColor = "white";
        wrapper.style.transform = `scale(${scaleFactor})`;
      }
    }, [isClicked]);

    // Function to handle mouse leave event

    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar
          src={image}
          name={name}
          size="sm"
          ref={ImageRef}
          variant="rounded"
          onClick={() => {
            openModal(image);
          }}
          style={{
            transition: "transform 0.3s ease",
            cursor: "pointer",
            transformOrigin: "left",
            border: "",
            borderRadius: "0.25rem",
          }}
        />
        <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <MDAvatar src={imageUrl} sx={{ width: "30rem", height: "30rem" }} alt="Modal" />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (Materials) {
      const initialRows = Materials.map((material, index) => {
        const {
          Status = "Unknown",
          SKU = "",
          Image = "",
          materialName = "",
          materialDesc = "",
          id = index + 1, // Use index as fallback for id
        } = material;

        return {
          id, // Ensure ID is unique and defaults to the current index
          Image,
          materialName,
          materialDesc,
          SKU,
          Status,
        };
      }).sort((a, b) => {
        if (a.Status < b.Status) {
          return -1;
        } else if (a.Status > b.Status) {
          return 1;
        } else {
          return a.materialName.localeCompare(b.materialName);
        }
      });

      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [Materials]);
  const columns = [
    {
      field: "Status",
      headerName: "Open/Edit",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <>
            {params.value != "Unknown" && (
              <MDTypography component={Link} to={`/Material/${params.row.id}`}>
                <MDBadge
                  badgeContent={"Open"}
                  size="sm"
                  container
                  circular
                  color={params.row.Status !== "1" ? "error" : "success"}
                />
              </MDTypography>
            )}
          </>
        );
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Open
        </MDTypography>
      ),
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <Project
            image={params.row.Image}
            name={params.row.materialName}
            openModal={openModal}
          ></Project>
        );
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Image
        </MDTypography>
      ),
    },
    {
      field: "materialName",
      headerName: "materialName",
      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.materialName}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Material Name
        </MDTypography>
      ),
    },
    {
      field: "materialDesc",
      headerName: "materialDesc",

      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.materialDesc}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Description
        </MDTypography>
      ),
    },
    {
      field: "SKU",
      headerName: "SKU",
      flex: 0.5,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.SKU}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          SKU
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Material List
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    moreActionsIcon
                    disableRowSelectionOnClick
                    loading={rows.length === 0}
                    slots={{ toolbar: Editrow }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                )}
              </MDBox>
            </Card>
            {modalOpen && <ImageModal imageUrl={selectedImage} onClose={closeModal} />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
