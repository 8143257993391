import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HoverGif from "components/HoverGif";
import Grid from "@mui/material/Grid";


import Feature_1Still from "layouts/Marketing/imgs/Feature_1.png"

import Feature_2Still from "layouts/Marketing/imgs/Feature_2.png"

import Feature_3Still from "layouts/Marketing/imgs/Feature_3.png"

import Feature_4Still from "layouts/Marketing/imgs/Feature_4.png"

function FeatureGifShowcase() {
  return (
    <MDBox pt={8} pb={6}>
      <MDTypography variant="h3" fontWeight="bold" mb={2}>
        See Our Features in Action
      </MDTypography>
      <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
          <HoverGif
            FeatureGif={"/images/Feature_1.gif"}
            FeatureStill={Feature_1Still}
            alt="Cutlist Generation"
            style={{ width: "100%", borderRadius: "12px" }}
          />
          <MDTypography variant="h6" mt={2}>
            {
              "Cutlist Generation: Generate precise and optimized cutlists in mere seconds, saving valuable time and reducing material waste. Our system ensures accuracy in every calculation, enabling seamless planning for your projects. Whether you're working on a small-scale or industrial-level operation, our cutlist generation tool provides unparalleled efficiency."
            }
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>

                    <HoverGif
            FeatureGif={"/images/Feature_2.gif"}
            FeatureStill={Feature_2Still}
       alt="Special Part Markings"
style={{ width: "100%", borderRadius: "12px" }}
/>
          <MDTypography variant="h6" mt={2}>
            Enhance part identification and workflow efficiency with advanced marking options.
            Easily mark miters for precise assembly and add QR codes to streamline tracking and
            inventory management. These markings simplify complex projects by enabling quick part
            identification and hassle-free progress monitoring on the shop floor or at the
            construction site.
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>

          <HoverGif
            FeatureGif={"/images/Feature_3.gif"}
            FeatureStill={Feature_3Still}
         alt="Import and Export Functionality"
style={{ width: "100%", borderRadius: "12px" }}
/>
          <MDTypography variant="h6" mt={2}>
          Simplify data management with versatile import and export capabilities. Import part details using various file formats to integrate seamlessly with your existing tools. Export your project data to Excel for comprehensive reporting or shareable documentation. Future-proof your operations with upcoming support for direct CNC saw exports, enabling end-to-end automation.
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <HoverGif
            FeatureGif={"/images/Feature_4.gif"}
            FeatureStill={Feature_4Still}
        alt="Multiple Optimization Algorithms"
style={{ width: "100%", borderRadius: "12px" }}
/>
          <MDTypography variant="h6" mt={2}>
            Optimize material usage with a choice of cutting-edge algorithms tailored to your priorities. Use the First Fit Decreasing algorithm for lightning-fast results or opt for the Genetic Mutation Algorithm to achieve higher yield and reduce waste. These algorithms adapt to your unique requirements, balancing speed and efficiency for optimal performance.
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default FeatureGifShowcase;
