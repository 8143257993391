/* eslint-disable no-unused-vars */
import { lazy, useContext, useEffect, useRef, useState } from "react";


import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import colors from "assets/theme-dark/base/colors";
import MTSwitch from "components/MTSwitch";
// @mui material components
import {
  AppBar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MTSelect from "components/MTSelect";
import { useParams } from "react-router-dom";
// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
// Material Dashboard 2 React base styless
import breakpoints from "assets/theme/base/breakpoints";
import MDSnackbar from "components/MDSnackbar";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import Solver from "layouts/optimization/components/Solver";
import { AuthContext, ProjectContext, useMaterialUIController } from "../../../../context";
import "./modalStyles.css";
// Images
const DieShapeForm = lazy(() => import("components/DieShapeForm"));
const CuttingResults = lazy(() => import("layouts/optimization/components/CuttingResults"));
const PartList = lazy(() => import("layouts/optimization/components/Parts"));



const Header = ({ Diedata }) => {
  const [Fraction, setFractionView] = useState(true);
  const GeneticButtonRef = useRef(null);
  const [solverConfig, setSolverConfig] = useState(0);
  const [solverInputParts, setSolverInputParts] = useState(0);
  const solverRef = useRef();
  const [formState, setFormState] = useState({
    DieName: "",
    StockLength: 120,
    Iterations: 100,
    OverMeasure: 0,
    UsefulScrap: 0,
    DieWeight: 0,
    Generations: 0,
    Parts: {},
    results: {},
    DieProject: "",
    DieDesc: "",
    FormDate: "",
    PrintQR: false,
    DropLabels: false,
    OptType: 0,
    OutputType: 0,
    SawKerf: 0,
    Randomness: 0,
    Mutation: 0,
    Crossover: 0,
    FractionView: Fraction,
    MaterialFinish: "",
    Order: "",
    // ... other parameters
  });

  const onResult = async (value) => {
    handleChange("results")(value);
  };
  function findSmallestIndexValue() {
    if (Array.isArray(formState.Parts) && formState.Parts.length > 0) {
      return Math.min(...formState.Parts.map((item) => convertToMixedFraction(item[2])));
    }
    return 0; // Or another appropriate default value
  }

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { optimizeId } = useParams();
  const {
    projects,
    Cutlists,
    Materials,
    addMaterial,
    convertToMixedFraction,
    OptimizeTypes,
    InfoTooltip,
    OutputTypes,
  } = useContext(ProjectContext); ///addMaterial
  const { Subscription, AppcurrentUser, SBMess } = useContext(AuthContext); ///addMaterial
  const setFraction = (value = "") => {
    if (value == "") {
      setFractionView(false);
    } else {
      setFractionView(value);
    }
  };
  useEffect(() => {
    const inputParts = {
      PartList: formState.Parts,
    };

    const config = [
      formState.StockLength,
      3,
      formState.OverMeasure,
      0,
      formState.UsefulScrap,
      formState.Iterations,
      formState.Generations,
      formState.Crossover,
      formState.Mutation,
      formState.Randomness,
      formState.SawKerf,
    ];

    setSolverInputParts(inputParts);
    setSolverConfig(config);
  }, [formState]);

  const [DarkString, setDarkString] = useState("");
  const wrapperRef = useRef(null);
  const ImageRef = useRef(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [initialState, setInitialState] = useState({});

  const [UniqueError, setUniqueError] = useState(false);
  const [PartLengthError, setPartLengthError] = useState(false);
  const [isDirty, setisDirty] = useState(false);
  const [StockWeight, setStockWeight] = useState(0);
  const [OptLength, setOptLength] = useState(0);
  const [TotalParts, setTotalParts] = useState(0);
  const [JobOptions, setJobOptions] = useState([]);
  const filter = createFilterOptions();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [OptLockSB, setOptLock] = useState(false);
  const [ModalData, setModalData] = useState();

  const handleChange = (field) => (newValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: newValue, // Update the specific field with newValue
    }));
  };

  const confirmUnique = () => {
    const hasDuplicates = (arr) => new Set(arr).size !== arr.length;
    const PartArray = formState.Parts.map((item) => item[0]);
    setUniqueError(hasDuplicates(PartArray));
    return hasDuplicates(PartArray);
  };

  useEffect(() => {
    const isChanged =
      formState.SawKerf !== initialState.SawKerf ||
      formState.StockLength !== initialState.StockLength ||
      formState.OverMeasure !== initialState.OverMeasure ||
      formState.UsefulScrap !== initialState.UsefulScrap ||
      formState.Iterations !== initialState.Iterations ||
      formState.Generations !== initialState.Generations ||
      formState.Crossover !== initialState.Crossover ||
      formState.Randomness !== initialState.Randomness ||
      formState.Mutation !== initialState.Mutation ||
      formState.DieName !== initialState.DieName ||
      formState.DieDesc !== initialState.DieDesc ||
      JSON.stringify(formState.Parts) !== JSON.stringify(initialState.Parts) ||
      formState.DieProject !== initialState.DieProject ||
      formState.DieWeight !== initialState.DieWeight ||
      formState.results !== initialState.Results ||
      formState.MaterialFinish !== initialState.MaterialFinish ||
      formState.Order !== initialState.Order ||
      formState.DropLabels !== initialState.DropLabels ||
      formState.PrintQR !== initialState.PrintQR ||
      formState.FormDate !== initialState.FormDate;

    setisDirty(isChanged);
  }, [
    formState,
    initialState, // To ensure we compare against the latest initial state
  ]);
  const closeWarningSB = () => setWarningSB(false);
  const closeOptLockSB = () => setOptLock(false);

  const [resultLock, setresultLock] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [UniqueModalOpen, setUniqueModalOpen] = useState(false);
  const [PartLengthModalOpen, setPartLengthModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const openUniqueModal = () => {
    setUniqueModalOpen(true);
  };
  const closeUniqueModal = () => {
    setUniqueModalOpen(false);
  };
  const openPartLengthModal = () => {
    setPartLengthModalOpen(true);
  };
  const closePartLengthModal = () => {
    setPartLengthModalOpen(false);
  };
  const SetandConfirmUsefulScrap = (value) => {
    const smallestParts = findSmallestIndexValue();

    if (smallestParts < value) {
      handleChange("UsefulScrap")(smallestParts);
    } else {
      handleChange("UsefulScrap")(value);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const DynamicAlert = (color, icon, title, Open, Close, content = "") => {
    return (
      <MDSnackbar
        color={color}
        icon={icon}
        title={title}
        content={content}
        dateTime="now"
        open={Open}
        onClose={Close}
        close={Close}
        bgWhite
      />
    );
  };
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Advanced Mode Activated"
      content="The default settings work well for a vast majority of situations, it you are having trouble with optimizing time or low yeild results. Adjusting these values may help."
      dateTime="now"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderOptLockSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Cutlist Locked"
      content="Clear optimization to edit locked values."
      dateTime="now"
      open={OptLockSB}
      onClose={closeOptLockSB}
      close={closeOptLockSB}
      bgWhite
    />
  );
  const syncDesc = async () => {
    const newEntry = Materials.find((die) => die.materialName === formState.DieName);
    const newDesc = newEntry ? newEntry.materialDesc : formState.DieDesc;
    const newWeight = newEntry ? newEntry.Weightft : formState.DieWeight;
    handleChange("DieDesc")(newDesc.replace(/"/g, '"'));
    handleChange("DieWeight")(newWeight);
    handleChange("ImageURL")(newEntry?.Image || "");
  };
  const handleClearOpt = (event) => {
    event.preventDefault();
    handleChange("results")({ Results: "" });
    if (tabValue == 2) {
      setTabValue(1);
    }
    //change tab if tab is cutting
  };
  useEffect(() => {
    setresultLock(formState.results != { Results: "" } && formState.results != `{"Results": ""}`);
  }, [formState.results]);
  useEffect(() => {
    setDarkString(darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleSave = (event) => {
    event.preventDefault();
    const SaveData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error("No user is signed in.");
        return;
      }
      const userId = user.uid;

      const { Parts, ...restOfFormState } = formState;
      const formDataObject = { ...restOfFormState, Parts: JSON.stringify(Parts) };

      const projectRef = doc(getFirestore(), "customers", userId, "Cutlists", optimizeId);
      try {
        await updateDoc(projectRef, formDataObject);
        setisDirty(false);
        openModal();
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    };

    SaveData();
  };
  useEffect(() => {
    if (formState.Parts.length) {
      const Total = formState.Parts?.reduce((acc, item) => {
        return acc + parseInt(item[1], 10);
      }, 0);
      setTotalParts(Total);
    }
  }, [formState.Parts]);
  useEffect(() => {
    let Diedata2 = Cutlists.find((item) => {
      return item?.id == optimizeId;
    });

    if (Diedata2) {
      const SawKerf = Diedata2.SawKerf || "0";
      const StockLength = Diedata2.StockLength || 120;
      const OverMeasure = Diedata2.OverMeasure || 0;
      const Parts = JSON.parse(Diedata2.Parts) || [];
      const UsefulScrap = Diedata2.UsefulScrap || 0;
      const Iterations = Diedata2.Iterations || "";
      const Generations = Diedata2.Generations || 100;
      const Crossover = Diedata2.Crossover || "";
      const Randomness = Diedata2.Randomness || "";
      const Fraction2 = Diedata2.Fraction;
      const Mutation = Diedata2.Mutation || "";
      const DieName = Diedata2.DieName || "";
      const DieDesc = Diedata2.DieDesc || "";

      const OptType = Diedata2.OptType || "0";
      const OutputType = Diedata2.OutputType || "0";

      const DieProject = Diedata2.DieProject || "";
      const DieWeight = Diedata2.DieWeight || 0;
      const Results = Diedata2.Results || JSON.parse('{"Results":""}');
      const MaterialFinish = Diedata2.MaterialFinish || "";
      const Order = Diedata2.Order || "";
      const FormDate = Diedata2.createdAt || "";
      const DropLabelsi = Diedata2.DropLabels || 0;
      const PrintQR = Diedata2.PrintQR || false;
      setInitialState({
        SawKerf: SawKerf,
        StockLength: StockLength,
        OverMeasure: OverMeasure,
        UsefulScrap: UsefulScrap,
        Iterations: Iterations,
        Generations: Generations,
        Crossover: Crossover,
        OptType: OptType,
        OutputType: OutputType,
        Randomness: Randomness,
        Fraction: Fraction,
        Mutation: Mutation,
        DieName: DieName,
        DieDesc: DieDesc,
        Parts: Parts,
        DieProject: DieProject,
        DieWeight: DieWeight,
        Results: Results,
        MaterialFinish: MaterialFinish,
        Order: Order,
        FormDate: FormDate,
        DropLabels: DropLabelsi,
        PrintQR: PrintQR,
      });
      handleChange("SawKerf")(SawKerf);
      handleChange("StockLength")(StockLength);
      handleChange("OverMeasure")(OverMeasure);
      handleChange("DropLabels")(DropLabelsi);
      handleChange("PrintQR")(PrintQR);
      handleChange("OutputType")(OutputType);
      handleChange("OptType")(OptType);
      handleChange("FractionView")(Fraction2);
      setFraction(Fraction2);
      handleChange("Iterations")(Iterations);
      handleChange("Generations")(Generations);
      handleChange("Crossover")(Crossover);
      handleChange("Randomness")(Randomness);
      handleChange("Mutation")(Mutation);
      handleChange("DieName")(DieName);
      handleChange("DieDesc")(DieDesc);
      handleChange("Parts")(Parts);
      handleChange("DieProject")(DieProject);
      handleChange("UsefulScrap")(UsefulScrap);

      //sum total parts here
      const Total = Array.isArray(Parts)
        ? Parts.reduce((acc, item) => {
            return acc + parseInt(item[1], 10);
          }, 0)
        : 0;

      setTotalParts(Total);

      const newEntry = Materials.find((die) => die.materialName === DieName);

      handleChange("ImageURL")(newEntry?.Image || "../../WALTEK.jpg");
      handleChange("DieWeight")(newEntry?.Weightft || 0);
      handleChange("results")(Results);
      handleChange("MaterialFinish")(MaterialFinish);
      handleChange("Order")(Order);
      handleChange("FormDate")(FormDate);

      setIsAdvancedOpen(false);

      if (Results != { Results: "" } && Results != `{"Results": ""}`) {
        setresultLock(true);
      }
      window.addEventListener("resize", handleTabsOrientation);
      // Call the handleTabsOrientation function to set the state with the initial value.
      handleTabsOrientation();
      // Remove event listener on cleanup
      return () => {
        // Cleanup code here (if needed)
        window.removeEventListener("resize", handleTabsOrientation);
      };
    }
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    setisDirty(false);
  }, [optimizeId, Diedata, Cutlists]);

  const csvToArr = (stringVal, splitter) => {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.replace(/\r/g, "").split(splitter));

    return rest;
  };

  const updateparts = async (newValue) => {
    var temp = await csvToArr(newValue, ",");
    setisDirty(true);
    handleChange("Parts")(temp);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (isAdvancedOpen) {
        wrapper.classList.add("show");
      } else {
        wrapper.classList.remove("show");
      }
    }
  }, [isAdvancedOpen]);

  useEffect(() => {
    setStockWeight((formState.DieWeight * (formState.StockLength / 12)).toFixed(2) + " lbs");
  }, [formState.DieWeight, formState.StockLength]);

  useEffect(() => {
    const Joboptions = Object.entries(projects)
      .map((project) => ({
        key: project[1].id,
        label: project[1].JobName,
        number: project[1].ProjectNum,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setJobOptions(Joboptions);
  }, [projects]);

  useEffect(() => {
    setOptLength(formState.StockLength - formState.OverMeasure);
  }, [formState.StockLength, formState.OverMeasure]);

  const ModalSubmit = (data) => {
    setModalData(data);
  };

  const Dieoptions = Materials.map((die) => ({
    key: die?.id,
    title: die.materialName,
    label: die.materialName,
  })).sort((a, b) => a.label.localeCompare(b.label));

  var AuthorNamehelp = "Terry Taylor"; // Find the entry where the EmployeeU matches Author
  var tabinsert = 0;
  if (tabValue == 2 && formState.results == { Results: "" }) {
    tabinsert = 1;
  } else {
    tabinsert = tabValue;
  }
  const children = ({ tabValue }) => {
    let content;
    switch (tabValue) {
      case 0:
        content = (
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  General Info
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <FormControl sx={{ minWidth: "100%" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      disableClearable
                      options={JobOptions || []}
                      value={
                        JobOptions.length > 0
                          ? JobOptions.find((option) => option.key === formState.DieProject) || null
                          : null
                      }
                      onChange={(event, newValue) => handleChange("DieProject")(newValue?.key)}
                      renderInput={(params) => <TextField {...params} label="Project" />}
               
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.Order}
                    onChange={(event) => {
                      handleChange("Order")(event.target.value);
                    }}
                    type="text"
                    label="Order #"
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.DieWeight}
                    onChange={(event) => {
                      handleChange("DieWeight")(event.target.value);
                    }}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    label="Die Weight Per Foot"
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={StockWeight || 0}
                    type="text"
                    label="Stock Weight"
                    fullWidth
                    disabled
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.MaterialFinish}
                    onChange={(event) => {
                      handleChange("MaterialFinish")(event.target.value);
                    }}
                    type="text"
                    label="Material Finish"
                    fullWidth
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={AuthorNamehelp || ""}
                    type="text"
                    label="Author"
                    fullWidth
                    readOnly
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  Material Settings
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={() => {
                    if (resultLock) {
                      handleShake();
                    }
                  }}
                  disabled="disabled"
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.StockLength}
                    onChange={(event) => {
                      handleChange("StockLength")(event.target.value);
                    }}
                    type="number"
                    label={
                      <>
                        <InfoTooltip title="Total Order Length of material." />
                        {"Stock Order Length"}
                      </>
                    }
                    fullWidth
                    disabled={resultLock}
                    required
                    autoFocus={!resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.OverMeasure}
                    onChange={(event) => {
                      handleChange("OverMeasure")(event.target.value);
                    }}
                    type="number"
                    required
                    label={
                      <>
                        <InfoTooltip title="The total length of material lost removing unusable ends for stock." />
                        {"Factory End Removal"}
                      </>
                    }
                    fullWidth
                    disabled={resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={OptLength}
                    type="text"
                    disabled
                    label={
                      <div>
                        <InfoTooltip title="Length of optimizable stock." />
                        {"Cutting Stock Length"}
                      </div>
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  color={darkMode ? "grey-400" : "dark"}
                >
                  Optimize Settings{" "}
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <MTSelect
                      options={OptimizeTypes}
                      value={formState.OptType}
                      onChange={(event) => {
                        handleChange("OptType")(event.target.value);
                      }}
                      label="Optimization Type"
                      fullWidth
                      error={false}
                      success={true}
                      disabled={resultLock}
                      defaultValue="0"
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <MTSelect
                      options={OutputTypes}
                      value={formState.OutputType}
                      onChange={(event) => {
                        handleChange("OutputType")(event.target.value);
                      }}
                      label="Output Type"
                      fullWidth
                      error={false}
                      success={true}
                      disabled={resultLock}
                      defaultValue="0"
                    />
                  </FormControl>
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                  onClick={resultLock ? handleShake : () => {}}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.SawKerf}
                    onChange={(event) => {
                      handleChange("SawKerf")(event.target.value);
                    }}
                    type="text"
                    label={
                      <div>
                        <InfoTooltip title="Saw Kerf is the amount of material lost each time a cut is made." />
                        {"Saw-Kerf"}
                      </div>
                    }
                    fullWidth
                    disabled={resultLock}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MTSwitch
                    checked={formState.PrintQR}
                    label={
                      <div>
                        <InfoTooltip title="Generate QR Code using format in Settings on Part labels." />
                        Print QR Codes On Labels
                      </div>
                    }
                    onChange={() => handleChange("PrintQR")(!formState.PrintQR)}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDInput
                    fontWeight="medium"
                    value={formState.DropLabels}
                    type="text"
                    label={
                      <div>
                        <InfoTooltip title="Generate labels for each unused drop longer than this value, set equal to stock length for no labels" />
                        Print Labels for Drops Longer than
                      </div>
                    }
                    onChange={(event) => {
                      handleChange("DropLabels")(event.target.value);
                    }}
                    fullWidth
                  />
                </MDBox>
                <MDBox className="advancedON" ref={wrapperRef}>
                  {formState.OptType == "1" && (
                    <>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.UsefulScrap}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="The minimum leftover stock length that the optimizer will attempt to use in cutting. This should cannot be set greater than the shortest part" />
                              Useful Scrap
                            </div>
                          }
                          onChange={(event) => {
                            SetandConfirmUsefulScrap(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.Iterations || "100"}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="Refers to the process of iterating through successive generations of the genetic algorithm" />
                              {"Iterations"}
                            </div>
                          }
                          onChange={(event) => {
                            handleChange("Iterations")(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock || formState.OptType == "0"}
                        />
                      </MDBox>

                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.Generations}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="Pertains to the creation of a new randomly ordered population of parts" />
                              {"Generation Size"}
                            </div>
                          }
                          onChange={(event) => {
                            handleChange("Generations")(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock || formState.OptType == "0"}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.Crossover || "0.8"}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="Amount of genes passing from parent to child per generation 0 being none and 1 being All." />
                              {"CrossOver"}
                            </div>
                          }
                          onChange={(event) => {
                            handleChange("Crossover")(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock || formState.OptType == "0"}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.Mutation || "0.3"}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="The probability of genes of the new generation mutating." />
                              {"Mutation"}
                            </div>
                          }
                          onChange={(event) => {
                            handleChange("Mutation")(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock || formState.OptType == "0"}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                        px={2}
                        onClick={resultLock ? handleShake : () => {}}
                      >
                        <MDInput
                          fontWeight="medium"
                          value={formState.Randomness || "0.3"}
                          type="text"
                          label={
                            <div>
                              <InfoTooltip title="High values favor higher genetic randomness, while vaules closer to 0 favor using existing genetic Material." />
                              {"Randomness"}
                            </div>
                          }
                          onChange={(event) => {
                            handleChange("Randomness")(event.target.value);
                          }}
                          fullWidth
                          disabled={resultLock || formState.OptType == "0"}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
                {renderWarningSB}
              </Grid>
            </Grid>
          </MDBox>
        );
        break;
      case 1:
        content = (
          <div>
            <PartList
              parts={formState.Parts}
              updateparts={updateparts}
              handleShake={handleShake}
              convertToMixedFraction={convertToMixedFraction}
              Fraction={Fraction}
              ToggleFraction={setFraction}
              setUniqueError={setUniqueError}
              setPartLengthError={setPartLengthError}
              PartLengthError={PartLengthError}
              UniqueError={UniqueError}
              FormData={{
                Die: formState.DieName,
                formState,
                Project: JobOptions.find((option) => option.key === formState.DieProject),
              }}
              resultLock={resultLock}
            />
          </div>
        );
        break;
      case 2:
        if (TotalParts > Subscription.items[0].price.product.metadata.PartLimit) {
          content = (
            <>
              Please upgrade your part limit is{" "}
              {Subscription.items[0].price.product.metadata.PartLimit} and this cutlist has{" "}
              {TotalParts} parts
            </>
          );
        } else {
          content = (
            <CuttingResults
              FractionView={Fraction}
              setFractionView={setFraction}
              OutputType={formState.OutputType}
              FormData={formState}
              Results={formState.results}
            />
          );
        }

        break;
      default:
        content = null;
    }
    return content;
  };
  const handleSetTabValue = async (event, newValue) => {
    if (newValue !== "3") {
      if (!PartLengthError) {
        setTabValue(newValue);
      } else {
        openPartLengthModal();
        if (newValue == "0" || newValue == "1") {
          setTabValue(newValue);
        }
      }
    }
  };
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const wrapper = ImageRef.current;
    const rect = wrapper.parentNode.parentNode.getBoundingClientRect();
    const scaleFactor = Math.max(rect.width, rect.height) / 100;
    if (!isClicked) {
      wrapper.style.zIndex = "99";
      wrapper.style.transform = `scale(1)`;
      wrapper.style.borderTopLeftRadius = "";
    } else if (wrapper.textContent != "p" && isClicked) {
      (wrapper.style.borderTopLeftRadius = "0"), (wrapper.style.zIndex = "9999999");
      wrapper.style.backgroundColor = "white";
      wrapper.style.transform = `scale(${scaleFactor})`;
    }
  }, [isClicked]);
  const handleClick = (event) => {
    setIsClicked(true);
  };
  const handleblur = (event) => {
    setIsClicked(false);
  };

  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const InsertData = async (data) => {
      try {
        addMaterial({
          Weightft: data.DieWeight,
          Costft: data.Costft,
          SKU: data.SKU,
          Status: "1",
          Notes: data.Notes,
          Image: data.ImageURL,
          ImagePath: data.ImagePath,
          materialName: data.DieName,
          materialDesc: data.DieApplication,
          Material: data.Material,
        });
      } catch (error) {
        console.error("Error fetching or processing data:", error);
      }
    };
    InsertData(ModalData); //send for insert
  };
  const Optimize = async (event) => {
    if (formState.OptType == "1") {
      console.log("use genetic opt");
      if (GeneticButtonRef.current) {
      }
    } else {
      let count1 = 0;
      let remainingParts = [...formState.Parts]; // Copy the array to avoid mutating the original
      let StockArray = [{ StockNumber: 1, RemainingLength: OptLength, PartCut: {} }]; // Initialize an array to hold stock lengths
      var TotalMaterialParts = 0;
      remainingParts.sort(
        (a, b) =>
          parseFloat(convertToMixedFraction(b[2])) - parseFloat(convertToMixedFraction(a[2]))
      ); // Sort parts by length (longest first)

      for (let index = 0; index < remainingParts.length; index++) {
        let partLength = parseFloat(convertToMixedFraction(remainingParts[index][2]));
        var partQty = remainingParts[index][1];
        if (!isNaN(remainingParts[index][1])) {
          partQty = parseInt(remainingParts[index][1]);
        }
        remainingParts[index][1] = partQty;
        remainingParts[index][2] = partLength;
        TotalMaterialParts += partQty * partLength;
        switch (remainingParts[index][3]) {
          case "Single Miter":
            partLength += 1;
            break;
          case "Double Miter":
            partLength += 2;
            break;
          default:
            break;
        }

        for (let P = partQty; P > 0; P--) {
          for (let i = 0; i < StockArray.length; i++) {
            if (StockArray[i].RemainingLength >= partLength) {
              StockArray[i].RemainingLength -= partLength + parseFloat(formState.SawKerf); // Reduce the remaining length of the stock
              if (
                !Object.prototype.hasOwnProperty.call(
                  StockArray[i].PartCut,
                  remainingParts[index][0]
                )
              ) {
                StockArray[i].PartCut[remainingParts[index][0]] = [
                  remainingParts[index][0],
                  1,
                  partLength,
                  remainingParts[index][3],
                ];
                count1++;
                // Add part to PartCut object if not already present
              } else {
                StockArray[i].PartCut[remainingParts[index][0]][1] += 1; // Increment quantity if part already exists in PartCut object
                count1++;
              }

              break;
            } else {
              if (i + 1 == StockArray.length) {
                let newStock = {
                  StockNumber: StockArray.length + 1,
                  RemainingLength: OptLength - (partLength + parseFloat(formState.SawKerf)),
                  PartCut: {},
                };
                newStock.PartCut[remainingParts[index][0]] = [
                  remainingParts[index][0],
                  1,
                  partLength,
                  remainingParts[index][3],
                ]; // Add part to PartCut object
                count1++;
                StockArray.push(newStock);
                break;
              }
            }
          }
        }
      }
      //setPartsList(temppart);

      const output = {
        Stock: OptLength,
        Sticks: StockArray.length,
        Results: [],
        OptYield: 0, // You need to define the calculateOptYield function
      };
      //yield will equal totalpartslength /(#stocks * length)
      StockArray.forEach((stock, index) => {
        let pickuplength = parseFloat(OptLength); //get sum of all previous parts in stock
        Object.keys(stock.PartCut).forEach((part) => {
          const partDetails = stock.PartCut[part];
          const resultItem = {
            qty: partDetails[1],
            part: partDetails[0],
            stock: `'S${index + 1}'`,
            cutLength: convertToMixedFraction(partDetails[2]) + '"',
            pickupStock: convertToMixedFraction(pickuplength) + '"',
            specialAction: partDetails[3], // You may need to modify this based on your requirements
            remainingStock:
              convertToMixedFraction(
                Math.max(
                  pickuplength - partDetails[1] * (partDetails[2] + parseFloat(formState.SawKerf)),
                  0
                )
              ) + '"',
            // You need to define the calculateRemainingStock function
          };
          pickuplength -=
            (parseFloat(partDetails[2]) + parseFloat(formState.SawKerf)) *
            parseFloat(partDetails[1]);
          output.Results.push(resultItem);
        });
      });

      output.OptYield = TotalMaterialParts / (parseFloat(formState.StockLength) * output.Sticks);

      handleChange("results")(output);
    }
  };
  const OptColorMode = resultLock ? "#ffbfbf" : "#b2b2b2";
  const handleShake = () => {
    setIsShaking(true);
    setOptLock(true);
    // Remove the animation after it's completed
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="5rem"
        borderRadius="xl"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "25%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={formState.ImageURL || ""}
              alt="profile-image"
              size="xl"
              shadow="sm"
              ref={ImageRef}
              className={isClicked ? "project-clicked" : ""}
              onClick={handleClick}
              onMouseLeave={handleblur}
              style={{
                transition: "transform 0.3s ease",
                cursor: "pointer",
                transformOrigin: "left calc(25% + 0px)",
                zIndex: "99",
              }}
            />
            {ModalOpen && DynamicAlert("success", "star", "Saved!", ModalOpen, closeModal)}
            {UniqueModalOpen &&
              DynamicAlert(
                "error",
                "star",
                "You have duplicate part numbers, correct before optimizing!",
                UniqueModalOpen,
                closeUniqueModal
              )}
            {PartLengthModalOpen &&
              DynamicAlert(
                "error",
                "star",
                "You have parts that are longer than the available cutting length, correct before optimizing!",
                PartLengthModalOpen,
                closePartLengthModal
              )}
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Autocomplete
                disablePortal
                id="combo-box"
                disableClearable
                freeSolo
                options={Dieoptions}
                value={formState.DieName}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      toggleOpen(true);
                      const vat = newValue.label?.replace("+Add ", "");
                      setDialogValue({
                        title: vat,
                        year: "",
                      });

                      handleChange("DieName")(newValue.inputValue);
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);

                    handleChange("DieName")(newValue.inputValue);
                    setDialogValue({
                      title: newValue.inputValue,
                      year: "",
                    });
                  } else {
                    handleChange("DieName")(newValue.label);
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.label;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const existingOption = options.find(
                    (option) => option.label === params.inputValue
                  );

                  if (params.inputValue && !existingOption) {
                    filtered.push({
                      id: params.inputValue,
                      label: `+Add ${params.inputValue}`,
                      key: params.inputValue,
                    });
                  }

                  return filtered;
                }}
                renderOption={(optionProps, option) => (
                  <li
                    key={option.key}
                    className={optionProps.className}
                    onClick={(event) => {
                      const selectedOption = Dieoptions.find((die) => die.key === option.key);
                      if (selectedOption) {
                        handleChange("DieName")(option.label);
                      } else {
                        if (
                          Materials.length >=
                          Subscription.items[0].price.product.metadata.MaterialLimit
                        ) {
                          SBMess(
                            "ERROR",
                            `You have reached your Maximum number of Materials. (${Subscription.items[0].price.product.metadata.MaterialLimit})`
                          );
                        } else toggleOpen(true);
                        var remov = option.label?.replace("+Add ", "");
                        setDialogValue({
                          title: remov,
                          year: "",
                        });
                      }
                    }}
                  >
                    {option.label}
                  </li>
                )}
                selectOnFocus
                renderInput={(params) => <TextField {...params} label="Part Number" />}
              />
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <form onSubmit={handleSubmit}>
                  <DialogTitle sx={{ backgroundColor: colors[DarkString]?.main }}>
                    Add a new Material Shape
                  </DialogTitle>
                  <DialogContent sx={{ backgroundColor: colors[DarkString]?.main }}>
                    <DialogContentText></DialogContentText>
                    <DieShapeForm SubmitForm={ModalSubmit} Diedata={dialogValue.title} />
                  </DialogContent>
                  <DialogActions sx={{ backgroundColor: colors[DarkString]?.main }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                  </DialogActions>
                </form>
              </Dialog>
              <MDBox>
                <MDInput
                  fontWeight="medium"
                  value={formState.DieDesc}
                  type="text"
                  label="Part Description"
                  onChange={(event) => {
                    handleChange("DieDesc")(event.target.value);
                  }}
                  sx={{ mt: 2 }}
                />
                <MDButton
                  variant="gradient"
                  color="secondary"
                  title="Update Part Description From Material Library"
                  onClick={syncDesc}
                  sx={{ mt: "16px" }}
                >
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    sync
                  </Icon>
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                sx={{ backgroundColor: `${colors["grey"]["300"]} !important` }}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Material"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
                <Tab
                  label="Parts"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      format_list_numbered_icon
                    </Icon>
                  }
                />

                <Tab
                  label={resultLock ? "Cutting" : "Optimize"}
                  title={resultLock ? "" : "Must Optimize First"}
                  className={resultLock ? "OptimizeMode" : ""}
                  sx={{ color: { OptColorMode } }}
                  disabled={
                    typeof formState.Parts.length == "undefined" || formState.Parts?.length == 0
                      ? true
                      : false
                  }
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      content_cut
                    </Icon>
                  }
                  onClick={(event) => {
                    event.preventDefault();

                    if (!resultLock && !confirmUnique() && !PartLengthError) {
                      Optimize();
                    } else if (confirmUnique() && !PartLengthError) {
                      openUniqueModal();
                    } else if (PartLengthError) {
                      openPartLengthModal();
                    }
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
          {resultLock && renderOptLockSB}

          <Grid item>
            <MDButton
              variant="gradient"
              className={`${isDirty ? "isDirty" : ""}`}
              color={"info"}
              onClick={handleSave}
            >
              <Icon sx={{ fontWeight: "bold" }}>save</Icon>
              Save
            </MDButton>
            {solverConfig !== 0 && false && solverInputParts !== 0 && (
              <Solver
                ref={solverRef}
                inputparts={solverInputParts}
                config={solverConfig}
                onResult={onResult}
              />
            )}
          </Grid>

          {resultLock && (
            <Grid item>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  transform: isShaking ? "translateY(-5px)" : "none",
                  transition: isShaking ? "transform 0.25s ease-in-out" : "none",
                }}
                onClick={handleClearOpt}
              >
                <Icon sx={{ fontWeight: "bold" }}>lockopen</Icon>
                Clear Optimization / Unlock
              </MDButton>
            </Grid>
          )}
        </Grid>
        {children({ tabValue })}
        <table id="linear-solver-results">
          <tbody>
            <tr></tr>
          </tbody>
        </table>
        <div id="linear-solver-solve"></div>
        <div id="linear-solver-layout"></div>
        {/*
        <button id="genetic-opt" ref={GeneticButtonRef}>
          dont press
        </button>*/}
      </Card>
    </MDBox>
  );
};

//// linear cutting below

export default Header;
