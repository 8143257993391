
// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";



function Information() {
  return (
    <MDBox pt={8} pb={6}>
      <MDTypography variant="h3" fontWeight="bold" mb={2}>
        Contact Support
      </MDTypography>
      <MDTypography variant="body1">
        If you have any questions or issues, please don’t hesitate to reach out.
      </MDTypography>
      <MDTypography variant="h6" mt={3}>
        Email: <a href="mailto:support@example.com">support@example.com</a>
      </MDTypography>
      <MDTypography variant="h6" mt={2}>
        Phone: (123) 456-7890
      </MDTypography>
    </MDBox>
  );
}

export default Information;
