/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*eslint-disable no-unused-vars*/
import { useState, useEffect, useContext, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import * as XLSX from "xlsx";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import Loading from "components/Loading";
import Footer from "examples/Footer";
import LinearProgress from "@mui/material/LinearProgress";
import Papa from "papaparse";
import Icon from "@mui/material/Icon";
import { ProjectContext,AuthContext } from "../../context";
import projectsTableData from "layouts/1doptimizer/data/projectsTableData";
import {
  DataGrid,
  useGridApiRef,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import "./modalStyles.css";
import { useParams } from "react-router-dom";
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "../../context";

const Tables = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const { projects, addCutlist, Cutlists, deleteCutlists, Materials, convertToMixedFraction  } =
    useContext(ProjectContext);  
    const {  SBMess } = useContext(AuthContext);
  const [selectedJob, setSelectedJob] = useState({ key: "all", label: "Show All" });
  const [Joboptions, setJoboptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { optimizeId } = useParams();
  const [selectedCellParams, setSelectedCellParams] = useState(null);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [Rows, setRows] = useState([]);
  const [FilteredRows, setFilteredRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const apiRef = useGridApiRef();
  const handleFileInputClick = () => {
    setisOpen(true);
  };

  const handleaddCutlist = (e) => {
    addCutlist({
      Finish: "",
      Die: "",
      Project: "{}",
      Material: "",
      Results: '{"Results": ""}',
      Parts: "{}",
      Description: "",
      yieldr: "",
      Order: "",
      Fraction:false,
      status: "",
    });
  };
  const onSubmit = async (data, file) => {
    const groupedData = Object.values(
      Object.entries(data.validData).reduce((acc, item) => {
        const key = `${item[1].DieName}-${item[1].Finish}-${item[1].StockLength}`;
        if (!acc[key]) {
          acc[key] = {
            Die: item[1].DieName,
            Finish: item[1].Finish,
            StockLength: item[1].StockLength,
            Parts: [],
          };
        }
        const partlength = convertToMixedFraction(item[1].PartLength);

        acc[key].Parts.push([item[1].name, item[1].qty, partlength, item[1].special]);
        return acc;
      }, {})
    );

    groupedData.forEach(element => {
 
      addCutlist({
        Finish: element.Finish,
        Die: element.Die,
        Project: "",
        Material: "",
        Results: '{"Results": ""}',
        Parts: JSON.stringify(element.Parts),
        Description: "",
        yieldr: "",
        Order: "",
        StockLength: element.StockLength,
        status: "",
      },true);
    });


  };
  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "Die Name",
      // This is the key used for this field when we call onSubmit.
      key: "DieName",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Name", "Die", "Die Name"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "WTF-4451",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Name is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {

      label: "StockLength",
      key: "StockLength",
      alternateMatches: ["Length", "Stock", "Order Length", "Stock Length"],
      fieldType: {
        type: "input",
      },
      example: "288",
      validations: [
        {
          rule: "required",
          errorMessage: "Stock Length is required",
          level: "error",
        },
        {
          rule: "regex",
          value: '^\\s*\\d+(\\.\\d+)?( \\d+/\\d+)?\\s*"?$', // Matches whole numbers only
          errorMessage:
            "Stock Length must be a decimal (e.g., 12.625) or mixed fraction (e.g., 12 5/8)",
          level: "error",
        },
      ],
    },
    {
      label: "Finish",
      key: "Finish",
      alternateMatches: ["Finish", "Color", "Coating"],
      fieldType: {
        type: "input",
      },
      example: "Mill",
      validations: [
        {
          rule: "required",
          errorMessage: "Finish is required",
          level: "error",
        },
      ],
    },
    {
      label: "Name",
      key: "name",
      alternateMatches: ["first name", "first"],
      fieldType: {
        type: "input",
      },
      example: "VM-101A",
      validations: [
        {
          rule: "required",
          errorMessage: "Name is required",
          level: "error",
        },
      ],
    },
    {
      label: "Qty",
      key: "qty",
      alternateMatches: ["Qty", "Qty", "Total", "Quantity"],
      fieldType: {
        type: "input",
      },
      example: "1",
      validations: [
        {
          rule: "required",
          errorMessage: "Quantity is required",
          level: "error",
        },
        {
          rule: "regex",
          value: "^[0-9]+$", // Matches whole numbers only
          errorMessage: "Quantity must be a whole number",
          level: "error",
        },
      ],
    },
    {
      label: "Part Length",
      key: "PartLength",
      alternateMatches: ["Length", "Part Length", "Measurement"],
      fieldType: {
        type: "input",
      },
      example: '12.625 (or) 12 5/8"',
      validations: [
        {
          rule: "required",
          errorMessage: "Part Length is required",
          level: "error",
        },
        {
          rule: "regex",
          value: '^\\s*\\d+(\\.\\d+)?( \\d+/\\d+)?\\s*"?$', // Matches whole numbers only
          errorMessage:
            "Quantity must be a decimal (e.g., 12.625) or mixed fraction (e.g., 12 5/8)",
          level: "error",
        },
      ],
    },
    {
      label: "Special",
      key: "special",
      alternateMatches: ["Mitering", "Special", "Fabrications"],
      fieldType: {
        type: "select",
        options: [
          { label: "Single Miter", value: "Single Miter" },
          { label: "Double Miter", value: "Double Miter" },
          { label: "Square", value: "Square" },
          { label: "Notching", value: "Notching" },
        ],
      },
      example: "Single Miter",
    },
  ];

  const onClose = (event) => {
    setisOpen(false);
  };
  const DeleteSelected = async (f) => {
    setLoading(true);
    const selectedRows = apiRef.current.getSelectedRows();
    const keys = Array.from(selectedRows.keys());
    await deleteCutlists(keys);



    setLoading(false);
  };

  const handleJobChange = (newValue) => {
    setSelectedJob(newValue);
  };
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const filter = createFilterOptions();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { columns, rows } = await projectsTableData({
          Cutlists,
          selectedJob,
          openModal,
          closeModal,
          Materials,
        });

        setTableData({ columns, rows });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (selectedJob) {
      fetchData();
      localStorage.setItem("selectedJob", selectedJob.key);
    }
  }, [selectedJob, Cutlists]);

  useEffect(() => {
    var hashr = window.location.hash.substring(1);
    const storedKey = localStorage.getItem("selectedJob");
    if (hashr === "" && storedKey) {
      hashr = storedKey;
    }
  
    const Joboptions2 = [
      { key: "all", label: "Show All" }, // Add "Show All" option
      ...Object.entries(projects || {})
        .map((project) => ({
          key: project[1].id,
          label: project[1].JobName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
    setJoboptions(Joboptions2);
  
    if (projects) {
      const matchingJob = Joboptions2.find((job) => job.key === hashr);
      if (matchingJob) {
        setSelectedJob(matchingJob);
      } else if (!hashr) {
        setSelectedJob(Joboptions2[0]); // Default to "Show All" if no hash or storedKey
      }
    }
  }, [projects]);

  useEffect(() => {
    if (tableData.rows.length > 0) {
      const initialRows = tableData.rows
        .map((Cutlist, index) => {
          const { Die, Description, yieldr, Order, StockLength, status, id, key } = Cutlist;
          const Finish = Cutlist.Finish;
          return {
            Finish,
            Die,
            Description,
            yieldr,
            Order,
            StockLength,
            status,
            id,
            key,
          };
        }) // Sort the rows by Status first, then by JobName
        .sort((a, b) => {
          // Compare by Status first
          if (a.Description < b.Description) {
            return -1; // a comes before b
          } else if (a.Description > b.Description) {
            return 1; // a comes after b
          } else {
            // If Status values are equal, compare by JobName
            // return a.Finish.localeCompare(b.Finish);
          }
        });
 
      setRows(initialRows);
   
      setLoading(false);
    } else {
      //setLoading(true);
    }
  }, [tableData]);
  useEffect(() => {
    if (Rows.length > 0) {
      if (selectedJob?.key === "all") {
        setFilteredRows(Rows); // Show all rows
        window.location.hash = "";
      } else if (selectedJob) {
        window.location.hash = selectedJob.key;
        const newFilteredRows = Rows.filter((row) => row.Finish.project === selectedJob.key);
        setFilteredRows(newFilteredRows);
      }
    }
  }, [selectedJob, Rows]); // Add Rows as a dependency
const [selectedRowKeys, setSelectedRowKeys] = useState([]);
const [buttonDisabled, setButtonDisabled] = useState(true);

const handleSelectionChange = (newSelection) => {
  setSelectedRowKeys(newSelection);
  setButtonDisabled(newSelection.length === 0); // Disable button if no rows are selected
};
  const ImageModal = ({ imageUrl, onClose }) => {

    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <MDAvatar src={imageUrl} sx={{width:"30rem", height:"30rem" }} alt="Modal" />
        </div>
      </div>
    );
  };
  const Editrow = ({}) => (
    <Card>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={5} md={3} lg={2}>
            <MDButton
              color="info"
              startIcon={<Icon>print</Icon>}
              onClick={(e) => {if( Object.keys(projects[0]).length != 1){
            
                handleaddCutlist(e);
              }else{

                SBMess("No Projects","There Must be at least one Project setup")
              }
           
              }}
            >
              New CutList
            </MDButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <MDButton
              color="error"
              startIcon={<Icon>remove</Icon>}
              disabled={buttonDisabled}
              onClick={() => {
                DeleteSelected();
              }}
            >
              Delete Selected
            </MDButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <MDButton
              onClick={handleFileInputClick}
              color="info"
              sx={{
                width: "100px",
                height: "50px",

                borderRadius: "5px",
                padding: "20px",
              }}
              startIcon={
                <Icon fontSize="small" style={{ transform: "rotate(180deg)" }}>
                  save_alt
                </Icon>
              }
            >
              Import
            </MDButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                p: 1,
              }}
            >
              <GridToolbarExport csvOptions={{ fileName: `${selectedJob.label} Cutlists` }} />
            </Box>
          </Grid>
          <Grid item xs={8} md={5} lg={3}>
            <Autocomplete
              disablePortal
              id="Select-Project"
              disableClearable
              freeSolo
              options={Joboptions}
              getOptionLabel={(option) => option.label}
              value={selectedJob}
              onChange={(event, value) => {
                handleJobChange(value);
              }}

              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Filter Project"
                    InputLabelProps={{
                      shrink: true, // Control label behavior
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={8} md={5} lg={3}>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                        Cut Lists
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <div>
                    <DataGrid
                      rows={FilteredRows}
                      columns={tableData.columns}
                      disableRowSelectionOnClick
                      disableColumnReorder={true}
                      checkboxSelection
                      sortingOrder={["asc", "desc"]}
                      disableColumnMenu={true}
                      disableEval
                      apiRef={apiRef}
                      editMode="cell"
                      loading={FilteredRows.length == 0}
                      slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: Editrow,
                      }}
                      onRowSelectionModelChange={(item)=>{
                        handleSelectionChange(item);
                      }}

                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          selectedCellParams,
                          setSelectedCellParams,
                        },
                      }}
                    />
                  </div>
                </MDBox>
                <MDBox>
                  {modalOpen && <ImageModal imageUrl={selectedImage} onClose={closeModal} />}
                  {isOpen && (
                    <ReactSpreadsheetImport
                      isOpen={isOpen}
                      onClose={onClose}
                      onSubmit={onSubmit}
                      fields={fields}
                      customTheme={{
                        colors: {
                          background: "white",
                          rsi: {
                            // your brand colors should go here
                            50: "blue",
                            500: "teal",
                            900: "red",
                          },
                        },
                      }}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
