//import { useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MKSocialButton from "components/MKSocialButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footer";


import routes from "routes";
import footerRoutes from "footer.routes";

import FeatureList from "./Sections/FeatureList"; // New section showcasing various features
import FeatureGifShowcase from "./Sections/FeatureGifShowcase"; 

//import { Padding } from "@mui/icons-material";
import bgImage from "layouts/Marketing/imgs/Hero_Features.png";
function FeatureDetails() {
  return (
    <>
      <DefaultNavbar routes={routes} light={true} sticky />
      <MDBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MDTypography
              variant="h1"
              color="white"
              mt={-5}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                padding: "1rem",
                borderRadius: "2rem",
                textShadow:
                  "0 1px 7px #000, 0 1px 7px #000, 0 3px 7px #000,0 3px 7px #000, 0 3px 7px #000",
              })}
            >
              Explore Our Powerful Features
            </MDTypography>
            <MDTypography
              variant="body1"
              color="white"
              textAlign="top"
              px={{ xs: 6, lg: 12 }}
              sx={{
                padding: "1rem",
                borderRadius: "2rem",
                textShadow:
                  "0 1px 7px #000, 0 1px 7px #000, 0 3px 7px #000,0 3px 7px #000, 0 3px 7px #000",
              }}
              mt={1}
            >
              See how our application can streamline your workflows with ease and efficiency.
            </MDTypography>
          </Grid>
        </Container>
      </MDBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -16,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <FeatureGifShowcase />
        <FeatureList />
        <MDBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MDTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Discover Features that Matter
                </MDTypography>
                <MDTypography variant="body1" color="text">
                  {"Our app's features are designed to boost productivity and ensure smooth operations."}
                </MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="linkedin"
                >
                  <i className="fab fa-linkedin" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MDBox>
      </Card>
      <MDBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MDBox>
    </>
  );
}

export default FeatureDetails;
