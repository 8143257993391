/*eslint-disable no-unused-vars*/

import { useEffect, useContext } from "react";
import LockIcon from "@mui/icons-material/Lock";
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { AuthContext } from "../../context";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import Tooltip from "@mui/material/Tooltip";
// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
const roleLevels = {
  none:0,
  basic: 1,
  pro: 2,
};
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  
  const { Role } = useContext(AuthContext);///either "","basic","pro" in that order pro has access to all basic features
  //routes containg a value "access" where that route will be unlocked only if the role is equal or higher
  const userRoleLevel = Role ? roleLevels[Role] : 0; 

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, key, href, route, display, access }) => {
      let returnValue;
  
      if (display) {
        const isAccessible = !access || userRoleLevel >= roleLevels[Role];
        const routeContent = (
          <SidenavCollapse
            key={`collapse-${key}`}
            name={name}
            icon={isAccessible ? icon : <LockIcon sx={{ marginLeft: 1 }} />}
            locked={isAccessible}
            active={key === collapseName}
          />
        );
  
        if (type === "collapse") {
          returnValue = href ? (
            <Link
              href={isAccessible ? href : ""}
              key={`link-${key}`} 
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              {routeContent}
            </Link>
          ) : (
            isAccessible ? (
              <NavLink key={`navlink-${key}`} to={isAccessible ? route : ""}>
                {routeContent}
              </NavLink>
            ) : (
              <div key={`locked-${key}`}>{routeContent}</div>
            )
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={`title-${key}`} // Unique key
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={`divider-${key}`} // Unique key
              sx={{
                opacity:
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                    ? 0.6
                    : 1,
                transition: "opacity 0.3s ease-in-out",
              }}
            />
          );
        }
      }
      return returnValue;
    }
  );
  

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox> 
      </MDBox>

      <Divider
        key={"iskey1"}
        sx={{
          opacity:
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
              ? 0.6
              : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
      />

      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}



export default Sidenav;
