/*eslint-disable no-unused-vars*/
import { getApp } from "firebase/app";
import { useContext, useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { AuthContext, useMaterialUIController } from "../../context";

// Material Dashboard 2 React example components
import Card from "@mui/material/Card";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDTypography from "components/MDTypography";
import PriceList from "components/PriceList";

// Dashboard components

const app = getApp();
function Subscription() {
/*we have collected products in authcontext, the features need to be added from the metadata */
  const { CheckoutSession } = useContext(AuthContext);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;

  const [tabValue, setTabValue] = useState(0);
  const [CheckoutOpening, setOpenCheckOut] = useState(false);
  const {Role , Subscription} = useContext(AuthContext);
  const handleSetTabValue = async (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setSelectedSubscription(Role);
  }, [Role]);
  const OpenCheckOut = ()=>{
    setOpenCheckOut(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <MDBox mt={4.5}>
       
          <PriceList selectPlan={CheckoutSession} sub={selectedSubscription} openCheck={OpenCheckOut}></PriceList>
           

          {(CheckoutOpening &&       <div className="modal">
                  <Card light={darkMode} sx={{height:"50%", width:"50%"}} className="modal-content">
                  <MDTypography variant="body2"   mb={3}>
                  redirecting you
                </MDTypography> 
                  </Card>
                </div>)}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
/*
<Projects />
              <OrdersOverview />*/
export default Subscription;
