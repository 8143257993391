/*eslint-disable no-unused-vars*/
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext, ProjectContext } from "../../context";

import {
  DataGrid,
  GridToolbar,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

const Tables = () => {
  const apiRef = useGridApiRef();
  const { projects, addProject } = useContext(ProjectContext);
  const { AppcurrentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [PMs, setPMs] = useState([]);
  const [loading, setLoading] = useState(true);

  const Editrow = ({}) => (
    <Card>
      <MDBox
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={5} md={3} lg={2}>
            <MDButton
              color="info"
              startIcon={<Icon>print</Icon>}
              onClick={(e) => {
                addProject({userId:AppcurrentUser.uid,JobName :"New",
                  Abbriviation:"NEW",
                  Job_Address1:"",
                  Status : "Active"})

           
              }}
            >
              New Project
            </MDButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>

              <GridToolbarExport sx={{}} csvOptions={{ fileName: `Project List` }} />
        
          </Grid>

          <Grid item xs={8} md={5} lg={3}>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );

  useEffect(() => {
    if (projects) {
      const initialRows = projects
        .map((project, index) => {
          const {
            JobName = "N/A", // Default to "N/A" if JobName is missing
            Abbriviation = "N/A",
            Address = "N/A",
            userId = "Unknown",
            Status = "Unknown",
            ProjectNum = '',
            id = index + 1, // Use index as fallback for id
          } = project;
      
          // Handle any other fields that need defaults
          const EmpName = project?.userId || "Error";

          return {
            id, // Ensure ID is unique and defaults to the current index
            JobName,
            Abbriviation,
            Address,
            ProjectNum,
            Status,
          };
        })
        .sort((a, b) => {
          if (a.Status < b.Status) {
            return -1;
          } else if (a.Status > b.Status) {
            return 1;
          } else {
            return a.JobName.localeCompare(b.JobName);
          }
        });
  
      setRows(initialRows);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [projects]);

  const columns = [
    {
      field: "Status",
      headerName: "Open/Edit",
      width: 100,
      renderCell: (params) => {

        return (
          <>
            {params.value !="Unknown" &&
          <MDTypography component={Link} to={`/Projects/${params.row.id}`}>
            <MDBadge
              badgeContent={"Open"}
              size="sm"
              container
              circular
              color={params.value == "Active" ? "error" : "success"}
            />
          </MDTypography>}
          </>
        );
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Open/Edit
        </MDTypography>
      ),
    },
    {
      field: "JobName",
      headerName: "Job Name",
      width: 180,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.JobName}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Job Name
        </MDTypography>
      ),
    },
    {
      field: "Abbriviation",
      headerName: "Abbriviation",
      align: "left",
      headerAlign: "left",
      width: 120,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.Abbriviation}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Abbriviation
        </MDTypography>
      ),
    },
    {
      field: "Address",
      headerName: "Job Location",
      width: 180,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.Address}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Job Location
        </MDTypography>
      ),
    },
    {
      field: "ProjectNum",
      headerName: "ProjectNum",
      width: 180,
      renderCell: (params) => {
        return <MDTypography variant="body2">{params.row.ProjectNum}</MDTypography>;
      },
      renderHeader: () => (
        <MDTypography variant="h6" align="center">
          Project Num
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" sx={{ fontSize: "24px" }} color="white">
                      Project List
                    </MDTypography>
                  </Grid>
     
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    moreActionsIcon
 
                    disableRowSelectionOnClick
                    loading={rows.length === 0}
                    slots={{ toolbar: Editrow, }}

                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,

                      },
                    }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Tables;
