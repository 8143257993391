/*eslint-disable no-unused-vars*/
import { useContext } from "react";

// @mui material components
import MTSelect from "components/MTSelect";
import { Card, FormControl, Tooltip } from "@mui/material";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MTSwitch from "components/MTSwitch";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useMaterialUIController, ProjectContext, AuthContext } from "../../../../context";

function PlatformSettings({ settings, handleChange }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { OptimizeTypes, InfoTooltip, OutputTypes } = useContext(ProjectContext); ///addMaterial
  const { AppcurrentUser } = useContext(AuthContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Cutlist Default Settings
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <MTSwitch
                checked={settings.FractionView}
                label={
                  <div>
                    <InfoTooltip title="Fraction or Decimal View" darkMode={darkMode} />
                    Fraction View
                  </div>
                }
                onChange={() => handleChange("FractionView")(!settings.FractionView)}
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <MDInput
                fontWeight="medium"
                value={settings.StockLength}
                onChange={(event) => {
                  handleChange("StockLength")(event.target.value);
                }}
                type="number"
                label={
                  <div>
                    <InfoTooltip title="Total Order Length of material." darkMode={darkMode} />
                    {"Stock Order Length"}
                  </div>
                }
                fullWidth
                required
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <MDInput
                fontWeight="medium"
                value={settings.OverMeasure}
                onChange={(event) => {
                  handleChange("OverMeasure")(event.target.value);
                }}
                type="number"
                label={
                  <div>
                    <InfoTooltip
                      title="The total length of material lost removing unusable ends for stock."
                      darkMode={darkMode}
                    />
                    {"Factory End Removal"}
                  </div>
                }
                fullWidth
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <FormControl sx={{ width: "100%" }}>
                <MTSelect
                  options={OptimizeTypes}
                  value={settings.OptType}
                  onChange={(event) => {
                    handleChange("OptType")(event.target.value);
                  }}
                  label="Optimization Type"
                  fullWidth
                  error={false}
                  success={true}
                  defaultValue="0"
                />
              </FormControl>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <FormControl sx={{ width: "100%" }}>
                <MTSelect
                  options={OutputTypes}
                  value={settings.OutputType}
                  onChange={(event) => {
                    handleChange("OutputType")(event.target.value);
                  }}
                  label="Output Type"
                  fullWidth
                  error={false}
                  success={true}
                  defaultValue="0"
                />
              </FormControl>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <MDInput
                fontWeight="medium"
                value={settings.SawKerf}
                onChange={(event) => {
                  handleChange("SawKerf")(event.target.value);
                }}
                type="text"
                label={
                  <div>
                    <InfoTooltip
                      title="Saw Kerf is the amount of material lost each time a cut is made."
                      darkMode={darkMode}
                    />
                    {"Saw-Kerf"}
                  </div>
                }
                fullWidth
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              px={2}
            >
              <MDInput
                fontWeight="medium"
                value={settings.DropLabels}
                onChange={(event) => {
                  handleChange("DropLabels")(event.target.value);
                }}
                type="text"
                label={
                  <div>
                    <InfoTooltip
                      title="Generate labels for each unused drop longer than this value, set equal to stock length for no labels"
                      darkMode={darkMode}
                    />
                    Print Labels for Drops Longer than
                  </div>
                }
                fullWidth
              />
            </MDBox>
            <MDBox
              component="fieldset"
              borderColor="grey.400"
              border={1}
              borderRadius="8px"
              padding="16px"
              sx={{ width: "100%", textAlign: "center" }}
            >
              <MDTypography component="legend" variant="subtitle1" fontWeight="bold" mb={2}>
                QR Code Settings
              </MDTypography>

              {/* Print QR Codes Switch */}
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <MTSwitch
                  checked={settings.PrintQR}
                  label={
                    <div>
                      <InfoTooltip
                        title="Generate QR Code using format in Settings on Part labels."
                        darkMode={darkMode}
                      />
                      Print QR Codes On Labels
                    </div>
                  }
                  onChange={() => handleChange("PrintQR")(!settings.PrintQR)}
                />
              </MDBox>

              {/* QR Prefix Input */}
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Tooltip
                  title="Prefix for URL generated for Part Labels. Format is Prefix -> Part Number -> Suffix -> Project Number"
                  placement="top"
                >
                  <MDBox width="100%" ml={0.5}>
                    <MDInput
                      fontWeight="medium"
                      value={settings.QRPrefix}
                      type="text"
                      label="QR Code Prefix"
                      onChange={(event) => handleChange("QRPrefix")(event.target.value)}
                      fullWidth
                    />
                  </MDBox>
                </Tooltip>
              </MDBox>

              {/* QR Suffix Input */}
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Tooltip
                  title="Suffix for URL generated for Part Labels. Format is Prefix -> Part Number -> Suffix -> Project Number"
                  placement="top"
                >
                  <MDBox width="100%" ml={0.5}>
                    <MDInput
                      fontWeight="medium"
                      value={settings.QRSuffix}
                      type="text"
                      label="QR Code Suffix"
                      onChange={(event) => handleChange("QRSuffix")(event.target.value)}
                      fullWidth
                    />
                  </MDBox>
                </Tooltip>
              </MDBox>

              {/* QR Code Format Display */}
              <MDTypography
                style={{
                  fontSize: "9px",
                  whiteSpace: "nowrap",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {`URL Preview`}
              </MDTypography>
              <MDTypography style={{ fontSize: "9px", whiteSpace: "nowrap" }}>
                {`${settings.QRPrefix}{Job-Number}${settings.QRSuffix}{Part-Name}`}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card pt={1} pb={2} px={2}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Cutlist Default Settings Continued
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.SingleMiter}
              onChange={(event) => {
                handleChange("SingleMiter")(event.target.value);
              }}
              type="number"
              inputProps={{ step: "1" }}
              label={
                <div>
                  <InfoTooltip
                    title="Length of material to add to a part length if there is a single miter."
                    darkMode={darkMode}
                  />
                  {"Single Miter Length Add"}
                </div>
              }
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.DoubleMiter}
              onChange={(event) => {
                handleChange("DoubleMiter")(event.target.value);
              }}
              type="number"
              inputProps={{ step: "1" }}
              label={
                <div>
                  <InfoTooltip
                    title="Length of material to add to a part length if there is a double miter."
                    darkMode={darkMode}
                  />
                  {"Double Miter Length Add"}
                </div>
              }
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.UsefulScrap}
              type="number"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="The minimum leftover stock length that the optimizer will attempt to use in cutting. This should cannot be set greater than the shortest part"
                  />
                  Useful Scrap
                </div>
              }
              onChange={(event) => {
                handleChange("UsefulScrap")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.Iterations || "100"}
              type="text"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="Refers to the process of iterating through successive generations of the genetic algorithm"
                  />
                  {"Iterations"}
                </div>
              }
              onChange={(event) => {
                handleChange("Iterations")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.Generations}
              type="text"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="Pertains to the creation of a new randomly ordered population of parts"
                  />
                  {"Generation Size"}
                </div>
              }
              onChange={(event) => {
                handleChange("Generations")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.Crossover}
              type="text"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="Amount of genes passing from parent to child per generation 0 being none and 1 being All."
                  />
                  {"CrossOver"}
                </div>
              }
              onChange={(event) => {
                handleChange("Crossover")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.Mutation || "0.3"}
              type="text"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="The probability of genes of the new generation mutating."
                  />
                  {"Mutation"}
                </div>
              }
              onChange={(event) => {
                handleChange("Mutation")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>

          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDInput
              fontWeight="medium"
              value={settings.Randomness}
              type="text"
              label={
                <div>
                  <InfoTooltip
                    darkMode={darkMode}
                    title="High values favor higher genetic randomness, while vaules closer to 0 favor using existing genetic Material."
                  />
                  {"Randomness"}
                </div>
              }
              onChange={(event) => {
                handleChange("Randomness")(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <ProfileInfoCard
            title="profile information"
            description={AppcurrentUser?.displayName || ""}
            info={{
              fullName: AppcurrentUser?.displayName || "",
              mobile: AppcurrentUser?.phoneNumber || "",
              email: AppcurrentUser?.email || "",
              location: "USA",
            }}
            social={[
              {
                link: "https://www.facebook.com/CreativeTim/",
                icon: <FacebookIcon />,
                color: "facebook",
              },
              {
                link: "https://twitter.com/creativetim",
                icon: <TwitterIcon />,
                color: "twitter",
              },
              {
                link: "https://www.instagram.com/creativetimofficial/",
                icon: <InstagramIcon />,
                color: "instagram",
              },
            ]}
            action={{ route: "", tooltip: "Edit Profile" }}
            shadow={false}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default PlatformSettings;
