/* eslint-disable react/function-component-definition */
/*eslint-disable no-unused-vars*/

import React, { useState, useEffect, useRef } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// @mui material components

export default async function useData({ Cutlists, selectedJob, openModal, closeModal, Materials }) {
  const Project = ({ image, name, openModal }) => {
    const ImageRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
      const wrapper = ImageRef.current;
      const rect =
        wrapper.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();
      const scaleFactor = Math.max(rect.width, rect.height) / 100;
      if (!isClicked) {
        wrapper.style.zIndex = "99";
        wrapper.style.transform = `scale(1)`;
      } else if (wrapper.textContent != "p" && isClicked) {
        wrapper.style.zIndex = "9999999";
        wrapper.style.backgroundColor = "white";
        wrapper.style.transform = `scale(${scaleFactor})`;
      }
    }, [isClicked]);

    // Function to handle mouse leave event

    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar
          src={image}
          name={name}
          size="sm"
          ref={ImageRef}
          variant="rounded"
          onClick={() => {
            openModal(image);
          }}
          style={{
            transition: "transform 0.3s ease",
            cursor: "pointer",
            transformOrigin: "left",
            border: "",
            borderRadius: "0.25rem",
          }}
        />
        <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );
  };

  const fetchData = async () => {
    function parseOptYield(results) {
      try {
        if (typeof results !== "object") {
          const parsedResults = JSON.parse(results);
          const out = parsedResults && parsedResults?.OptYield ? parsedResults?.OptYield * 100 : 0;
          return Number(out.toFixed(2));
        } else {
          let tempnumber = results.OptYield * 100;
          return Number(tempnumber.toFixed(2));
        }
      } catch (error) {
        return Number(0);
      }
    }

    try {
      var rows = [
        {
          id: "e",
          key: "OptR",
          Blank: "No Results",
        },
      ];
      var columns = [
        {
          field: "Blank",
          headerName: "No Results",
        },
      ];

      rows = Cutlists.map((item) => ({
        Finish:
          {
            Finish: item.MaterialFinish || "",
            project: item.DieProject || "",
            Image: Materials.find((die) => die.DieName === item.Die)?.Image,
          } || "",
        Die: item.DieName || "",
        Description: item.DieDesc || "",
        yieldr: parseOptYield(item.Results) || "",
        Order: item.Order || "",
        StockLength: item.StockLength || "",
        status: item.Results?.length <= 20 ? "NOT Optimized" : "Optimized",
        id: item.id || "",

        key: "OptR" + item.id,
      }));
      console.log([Cutlists,rows]);

      columns = [
        {
          field: "id",
          headerName: "Open",
          width: 100,
          renderCell: (params) => {
            const rowElement = params.api.getRowElement(params.id);
            if (rowElement) {
              rowElement.firstChild.style.justifyContent = "center";
            }

            return (
              <MDTypography component={Link} to={`/optimizer/${params.row.id}`} color="white">
                <MDBadge
                  badgeContent={"Open"}
                  size="sm"
                  container
                  circular
                  color={params.value == 2 ? "error" : "success"}
                />
              </MDTypography>
            );
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Open
            </MDTypography>
          ),
        },
        {
          field: "Die",
          headerName: "Shape Name",
          width: 200,
          renderCell: (params) => {
            return (
              <Project
                image={params.row.Finish?.Image || ""}
                name={params.row.Die}
                openModal={openModal}
              ></Project>
            );
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Shape Name
            </MDTypography>
          ),
        },
        {
          field: "Description",
          headerName: "Description",
          width: 180,
          renderCell: (params) => {
            return <MDTypography variant="body2">{params.row.Description}</MDTypography>;
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Description
            </MDTypography>
          ),
        },
        {
          field: "Finish",
          headerName: "Finish",
          width: 180,
          valueGetter: (params) => params.row.Finish?.Finish || "",
          renderCell: (params) => {
            return <MDTypography variant="body2">{params.row.Finish?.Finish || ""}</MDTypography>;
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Finish
            </MDTypography>
          ),
        },
        {
          field: "Order",
          headerName: "Order Number",
          width: 110,
          align: "left",
          headerAlign: "left",
          renderCell: (params) => {
            return <MDTypography variant="body2">{params.row.Order}</MDTypography>;
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Order #
            </MDTypography>
          ),
        },
        {
          field: "StockLength",
          headerName: "Stock Length",
          width: 180,
          renderCell: (params) => {
            return <MDTypography variant="body2">{params.row.StockLength}</MDTypography>;
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Stock Length
            </MDTypography>
          ),
        },
        {
          field: "yieldr",
          headerName: "Yield",
          width: 100,
          renderCell: (params) => {
            return (
              <MDTypography
                component="a"
                sx={{ fontSize: "1rem", color: params.value === 0 ? "red" : "green" }}
              >
                {params.value}%
              </MDTypography>
            );
          },
          renderHeader: () => (
            <MDTypography variant="h6" align="center">
              Yield
            </MDTypography>
          ),
        },
      ];

      if (Cutlists[0]?.Name) {

        return {
          columns: [
            {
              field: "id",
              headerName: "Error",
              width: 250,
              align: "center",
              color: "red",
              renderCell: (params) => {
                return (
                  <MDTypography component="a" key="Alert123" color="white">
                    <MDBadge
                      badgeContent="Not CutLists Yet"
                      title="Not CutLists Yet"
                      size="lg"
                      container
                      circular
                      color={"error"}
                    />
                  </MDTypography>
                );
              },
              renderHeader: () => (
                <MDTypography variant="h6" align="center">
                  Open
                </MDTypography>
              ),
            },
          ],
          rows: [
            {
              id: "No Cutlists Yet",
              key: "Alert1",
            },
          ],
        };
      } else {
        return { columns, rows };
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      return {
        columns: [{ Header: "Alert", accessor: "Alert", align: "center", color: "red" }],
        rows: [
          {
            id: "Alert1",
            key: "Alert12",
            Alert: (
              <MDTypography component="a" color="white">
                <MDBadge
                  badgeContent="Not CutLists Yet"
                  title="Not CutLists Yet"
                  size="lg"
                  container
                  circular
                  color={"error"}
                />
              </MDTypography>
            ),
          },
        ],
      }; // Return empty data in case of error
    }
  };

  return await fetchData();
}
