
// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function SupportFAQs() {
  return (
    <MDBox pt={8} pb={6}>
      <MDTypography variant="h3" fontWeight="bold" mb={2}>
        Frequently Asked Questions
      </MDTypography>
      <MDTypography variant="h6">How do I contact support?</MDTypography>
      <MDTypography variant="body1" mb={3}>
        You can contact us via email at support@example.com or call us at (123) 456-7890.
      </MDTypography>
      <MDTypography variant="h6">What is your return policy?</MDTypography>
      <MDTypography variant="body1">
        We offer a 30-day return policy on all products. Please visit our returns page for more details.
      </MDTypography>
    </MDBox>
  );
}

export default SupportFAQs;
