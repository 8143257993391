import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { sendEmailVerification } from "firebase/auth";
import { useMaterialUIController, setCurrentUser, AuthContext } from "context";
// Authentication layout components
import { signInWithEmailAndPassword } from "firebase/auth";
// Images
import bgImage from "assets/images/bg-sign-in-basic.webp";
import CoverLayout from "layouts/authentication/components/CoverLayout";

function Basic() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authType, signInWithGoogle, AppCurrentUser } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const myParam = searchParams.get("Multiple_Session");

  const [controller, dispatch] = useMaterialUIController(); //eslint-disable-line no-unused-vars
  const EmailRef = useRef(null);

  const [User, setUser] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordErr, setPasswordErr] = useState(true);
  const [UserErr, setUserErr] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [LoginFail, setLoginFail] = useState([]);

  useEffect(() => {
    const storedAuthState = AppCurrentUser;
    if (storedAuthState && storedAuthState.uid) {
      navigate("/dashboard");
    }
    if (myParam) {
      alert(
        "Your session is invalid, only one session can be active at a time, logging in again will log all other sessions out."
      );
    }
    ///ValidateEmail(EmailRef.current.querySelector("input").value);
  }, [AppCurrentUser]);

  const ValidateEmail = (value) => {
    //if valid email then set UserErr(false);
    setUser(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setUserErr(!emailRegex.test(value));
  };
  const ValidatePassword = (value) => {
    //if valid email then set UserErr(false);
    setPassword(value);
    const passwordRegex = /^(?=.*\d{2})(?=.*[!@#$%^&*()_+={}:;"'<>?,./\\|`~])(?=.*[a-zA-Z]).{8,}$/;
    setPasswordErr(!passwordRegex.test(value));
  };
  const handleSignin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(authType, User, Password);

      const user = userCredential.user;
      if (!user.emailVerified) {
        setLoginFail(["Email Not Verified, Check your email", user]);
        setShowSnackbar(true);
        return;
      } ///email verified

      setCurrentUser(dispatch, user);
      navigate("/dashboard");
    } catch (error) {
      setLoginFail(["Fail Login"]);
      setShowSnackbar(true);
      console.error("Error fetching or processing data:", error);
    }
  };
  const closeSnackbar = () => {
    setShowSnackbar(false); // Set state to hide the Snackbar
  };
  const renderContent = () => {
    if (LoginFail[0] === "Email Not Verified, Check your email") {
      return (
        <div>
          {LoginFail[0]}
          <button onClick={() => sendEmailVerification(LoginFail[1])}>Resend Verification</button>
        </div>
      );
    }
    return <div>{LoginFail[0]}</div>;
  };

  return (
    <CoverLayout image={bgImage}>
      <Card       sx={{marginTop:"10rem !important"}}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          p={2}
          mb={1}
          mt={2}
    
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDButton
            onClick={() => {
              signInWithGoogle();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
              <path d="M1 1h22v22H1z" fill="none" />
            </svg>{" "}
            Sign In
          </MDButton>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                ref={EmailRef}
                label="Email"
                error={UserErr ? true : false}
                success={UserErr ? false : true}
                fullWidth
                onChange={(event) => {
                  ValidateEmail(event.target.value);
                }}
              />
              {UserErr ? (
                <MDBox mb={2} sx={{ fontSize: "8px", color: "red" }}>
                  Input Valid Email{" "}
                </MDBox>
              ) : (
                ""
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                error={PasswordErr ? true : false}
                success={PasswordErr ? false : true}
                onChange={(event) => {
                  ValidatePassword(event.target.value);
                }}
                fullWidth
                onKeyUp={(event) => {
                  if (event.keyCode == 13) {
                    handleSignin();
                  }
                }}
              />
              {PasswordErr ? (
                <MDBox mb={2} sx={{ fontSize: "8px", color: "red" }}>
                  Password Doesnt Match Format 8 total Characters with 2 numbers and 2 Special
                  characters
                </MDBox>
              ) : (
                ""
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                onClick={() => {
                  if (!UserErr && !PasswordErr) {
                    handleSignin();
                  }
                }}
                disabled={!UserErr && !PasswordErr ? false : true}
                color="info"
                fullWidth
              >
                sign in
              </MDButton>

              <MDTypography
                component={Link}
                to="/authentication/reset-password"
                variant="button"
                color="info"
                fontWeight="light"
                textGradient
                sx={{
                  display: "block", // Ensures it's a block-level element
                  textAlign: "center", // Centers the text
                  width: "fit-content", // Ensures it spans the full width of its parent container
                  margin: "auto",
                  marginTop: "1rem", // Optional: Adds top margin to space it from the elements above
                }}
              >
                Forgot Password
              </MDTypography>
              <MDBox mt={3} mb={1} textAlign="center" style={{ marginBottom: "auto" }}>
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="warning"
        icon="star"
        title="Login Failed"
        content={renderContent()}
        dateTime="now"
        bgWhite
        open={showSnackbar} // Control visibility based on state
        onClose={closeSnackbar}
        close={closeSnackbar}
      />
    </CoverLayout>
  );
}

export default Basic;
